import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const commonRoutes = [
    {
        path: '/login',
        name: 'login',
        meta: { title: '登录' },
        component: () => import('../components/login.vue'),
    },
    { path: '/', redirect: '/home' },
]

// 本地所有的页面 需要配合后台返回的数据生成页面
export const asyncRoutes = {
    // ---------- 首页 ----------
    home: {
        path: 'home',
        name: 'home',
        meta: { title: '首页' },
        component: () => import('../views/home/home.vue'),
    },

    //--------钱包管理
    //----平台钱包
    externalWallet: {
        path: 'externalWallet',
        name: 'externalWallet',
        meta: { title: '平台钱包' },
        component: () => import('../views/wallet/external-wallet.vue'),
    },
    externalWalletBank: {
        path: 'externalWalletBank',
        name: 'externalWalletBank',
        meta: { title: '平台钱包银行卡' },
        component: () => import('../views/wallet/external-wallet-bank.vue'),
    },
    externalWalletDetails: {
        path: 'externalWalletDetails',
        name: 'externalWalletDetails',
        meta: { title: '平台钱包明细' },
        component: () => import('../views/wallet/external-wallet-details.vue'),
    },
    //----------乡米网钱包
    internalWallet: {
        path: 'internalWallet',
        name: 'internalWallet',
        meta: { title: '乡米网钱包' },
        component: () => import('../views/wallet/Internal-wallet.vue'),
    },
    internalWalletDetails: {
        path: 'internalWalletDetails',
        name: 'internalWalletDetails',
        meta: { title: '乡米网钱包明细' },
        component: () => import('../views/wallet/Internal-wallet-details.vue'),
    },
    internalWalletBank: {
        path: 'internalWalletBank',
        name: 'internalWalletBank',
        meta: { title: '乡米网钱包银行卡' },
        component: () => import('../views/wallet/Internal-wallet-bank.vue'),
    },
    internalWalletUserid: {
        path: 'internalWalletUserid',
        name: 'internalWalletUserid',
        meta: { title: '乡米网钱包userid' },
        component: () => import('../views/wallet/Internal-wallet-userId.vue'),
    },


    //--------商户管理
    merchantList: {
        path: 'merchantList',
        name: 'merchantList',
        meta: { title: '商户列表' },
        component: () => import('../views/merchant/merchant-list.vue'),
    },
    merchantDetails: {
        path: 'merchantDetails',
        name: 'merchantDetails',
        meta: { title: '商户明细' },
        component: () => import('../views/merchant/merchant-details.vue'),
    },
    merchantOrder: {
        path: 'merchantOrder',
        name: 'merchantOrder',
        meta: { title: '乡米网订单' },
        component: () => import('../views/merchant/merchant-order.vue'),
    },


    //--------订单管理
    externalOrder: {
        path: 'externalOrder',
        name: 'externalOrder',
        meta: { title: '平台订单' },
        component: () => import('../views/order/external-order.vue'),
    },
    internalOrder: {
        path: 'internalOrder',
        name: 'internalOrder',
        meta: { title: '乡米网订单' },
        component: () => import('../views/order/Internal-order.vue'),
    },

    //---------------权限
    jurisdictionRole: {
        path: 'jurisdictionRole',
        name: 'jurisdictionRole',
        meta: { title: '角色管理' },
        component: () => import('../views/jurisdiction/jurisdiction-role.vue'),
    },
    jurisdictionRoleAdd: {
        path: 'jurisdictionRoleAdd',
        name: 'jurisdictionRoleAdd',
        meta: { title: '角色管理权限设置' },
        component: () => import('../views/jurisdiction/jurisdiction-role-add.vue'),
    },
    jurisdictionLog: {
        path: 'jurisdictionLog',
        name: 'jurisdictionLog',
        meta: { title: '管理员日志' },
        component: () => import('../views/jurisdiction/jurisdiction-log.vue'),
    },


    // -----------  404页面  --------
    roleError: {
        path: 'roleError',
        name: 'roleError',
        meta: { title: '404错误' },
        component: () => import('../components/404.vue'),
    },

}

const createRouter = () => new Router({
    routes: commonRoutes,
})

const router = createRouter()

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher
}

export default router
