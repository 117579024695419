import store from '@/store'

// 左侧菜单栏数据
var menuArray = store.state.menuItems
console.log('左侧菜单栏数据', menuArray)

// 手动添加临时路由
var menuTemporary = [
    {
        name: 'home', // 要跳转的路由名称 不是路径
        type: 'el-icon-nav_icon_sy', // icon类型
        text: '首页', // 文本内容
    },
    {
        text: '钱包管理',
        type: 'el-icon-nav_icon_jy',
        children: [
            {
                type: 'el-icon-nav_icon_yh',
                name: 'externalWalletBank',
                text: '平台钱包银行卡',
            },
            {
                type: 'el-icon-nav_icon_jy',
                name: 'externalWalletDetails',
                text: '平台钱包明细',
            },
            {
                type: 'el-icon-nav_icon_jy',
                name: 'internalWalletDetails',
                text: '乡米网钱包明细',
            },
            {
                type: 'el-icon-nav_icon_jy',
                name: 'internalWalletBank',
                text: '乡米网钱包银行卡',
            },
            {
                type: 'el-icon-nav_icon_jy',
                name: 'internalWalletUserid',
                text: '乡米网钱包userid',
            },


        ]
    },
 /*   {
        text: '商户管理',
        type: 'el-icon-nav_icon_sp',
        children: [
            {
                type: 'el-icon-nav_icon_jy',
                name: 'merchantDetails',
                text: '商户明细',
            },
        ],
    },*/
    {
        text: '订单管理',
        type: 'el-icon-nav_icon_sp',
        children: [],
    },
    {
        text: '权限管理',
        type: 'el-icon-nav_icon_sp',
        children: [
            {
                type: 'el-icon-nav_icon_jy',
                name: 'jurisdictionRoleAdd',
                text: '角色管理权限设置',
            },
        ],
    },
    {
        text: '商户管理',
        type: 'el-icon-nav_icon_hd',
        children: [
            {
                type: 'el-icon-nav_icon_jy',
                name: 'merchantDetails',
                text: '商户明细',
            },
        ],
    },
    // {
    //     text: '合伙人管理',
    //     type: 'el-icon-nav_icon_hd',
    //     children: [{
    //         type: 'el-icon-nav_icon_yh',
    //         name: 'auditcashextractPartnerdetails',
    //         text: '合伙人提取保证金详情',
    //     },
    //     {
    //         type: 'el-icon-nav_icon_yh',
    //         name: 'partenrIncomeOrder',
    //         text: '合伙人收益-订单明细',
    //     },
    //     {
    //         type: 'el-icon-nav_icon_yh',
    //         name: 'partenrIncomeEarnings',
    //         text: '合伙人收益-收益明细',
    //     },
    //     {
    //         type: 'el-icon-nav_icon_yh',
    //         name: 'partenrIncomeDeposit',
    //         text: '合伙人收益-提现明细',
    //     }]
    // },
    // {
    //     text: '审核管理',
    //     type: 'el-icon-nav_icon_hd',
    //     children: []
    // },

    // {
    //     text: '营销中心',
    //     type: 'el-icon-nav_icon_yx',
    //     children: [
    //         {
    //             type: 'el-icon-nav_icon_yh',
    //             name: 'marketingFirstclassifi',
    //             text: '金刚区商品添加',
    //         },
    //         {
    //             type: 'el-icon-nav_icon_yh',
    //             name: 'marketingSecondclassifi',
    //             text: '二级分类设置',
    //         },
    //         {
    //             type: 'el-icon-nav_icon_yh',
    //             name: 'marketingSecondcommdityclassifi',
    //             text: '二级分类商品设置',
    //         },
    //     ]
    // },
    // {
    //     text: '供应商管理',
    //     type: 'el-icon-nav_icon_gys',
    //     children: [{
    //         type: 'el-icon-nav_icon_yh',
    //         name: 'supplierFreightset',
    //         text: '运费模板设置',
    //     },]
    // },
    // {
    //     text: '基础设置',
    //     type: 'el-icon-nav_icon_sz',
    //     children: [],
    // },
    // // 用户管理
    // {
    //     text: '用户管理',
    //     type: 'el-icon-nav_icon_yh',
    //     children: [
    //         {
    //             type: 'el-icon-nav_icon_yh',
    //             name: 'userDetails',
    //             text: '用户详情',
    //         }
    //     ],
    // },
    // // 角色管理
    // {
    //     text: '团队管理',
    //     type: 'el-icon-nav_icon_js',
    //     children: [
    //         // 404 页面
    //         {
    //             name: 'roleError', // 要跳转的路由名称 不是路径
    //             type: 'el-icon-nav_icon_yh', // icon类型
    //             text: '404错误', // 文本内容
    //         },
    //         // 添加角色
    //         {
    //             name: 'roleAdd', // 要跳转的路由名称 不是路径
    //             type: 'el-icon-nav_icon_yh', // icon类型
    //             text: '添加角色', // 文本内容
    //         },
    //     ],
    // },
]

for (let i = 0; i < menuArray.length; i++) {
    // 临时路径 获取 判断
    // 开始一层判断  首先判断有没有有没有多种页面
    if (menuArray[i].children) {
        // 开始二层判断  判断名称是否一致
        if (menuArray[i].text == menuTemporary[i].text) {
            // console.log('获取二者的text', menuArray[i].text, menuTemporary[i].text)


            // 开始三层添加  把手动路径加入默认路由内部
            menuTemporary[i].children.push(...menuArray[i].children)


            // console.log('获取合并后的数组路由', menuTemporary)
        }
    }
}

export default menuTemporary;
